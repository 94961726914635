import React from "react"

const IanddStylesWrapper = ({ children }) => {
    return (
      <div className="iandd-styles-wrapper">
        <style>
          {`
            .content-content a[href*="//"]:not([href*=".fastly.com"]):not([href*="/fastly.com"]) {
              white-space: normal !important;
              overflow-wrap: anywhere;
              word-break: break-word;
            }
          `}
        </style>
        {children}
      </div>
    );
  };
  
  export default IanddStylesWrapper;